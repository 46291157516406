import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { useGetCodeForLogin, useIsReactNativeWebView } from '@/shared/hooks'
import { LocalStorage, sendMessageToApp, sendToSentryWithExtra } from '@/shared/utils'

export default function Home() {
  const isWebView = useIsReactNativeWebView()
  const { data, isError, requestCodeForLogin } = useGetCodeForLogin()
  const [accessToken, setAccessToken] = useState<string | null>(null)

  const requestAccessToken = useCallback(async () => {
    if (typeof window === undefined) return
    try {
      const {
        data: { access_token, refresh_token },
      } = await axios.post<IOauthTokenResponse>('/api/oauth/token', { code: data })
      setAccessToken(access_token)
      LocalStorage.setItem('accessToken', access_token)
      LocalStorage.setItem('refreshToken', refresh_token)
      sendMessageToApp<string>('Message', 'SuccessfulLogin')
    } catch (e) {
      sendMessageToApp<string>('Message', 'FailedLogin')
      if (e instanceof Error) {
        sendToSentryWithExtra(e)
      }
    }
  }, [data])

  useEffect(() => {
    if (data !== '') {
      requestAccessToken()
    }
  }, [data, isError, requestAccessToken])

  return null
}
